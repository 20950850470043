import React, {createContext, ReactNode, useContext, useState} from "react";


export interface IWithdrawalForm {
    currentSavings:  string;
    monthlySalary: string;
    age: number;
    retirementAge: number;
    monthlyContribution: number
}

interface IGlobalStateProps {
    withdrawalData: IWithdrawalForm | null;
    setWithdrawalData: (data: IWithdrawalForm) => void;
}

const GlobalStateContext = createContext<IGlobalStateProps | undefined>(undefined);

const GlobalStateProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [withdrawalData, setWithdrawalData] = useState<IWithdrawalForm | null>(null)
    return (
        <GlobalStateContext.Provider value={{withdrawalData, setWithdrawalData}}>
            {children}
        </GlobalStateContext.Provider>
    )
}

const useGlobalState = ():IGlobalStateProps => {
    const context = useContext(GlobalStateContext);

    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
}

export {GlobalStateProvider, useGlobalState};