import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './withdrawalBreakdown.scss';
import {useGlobalState} from "../../Contexts/GlobalState";
import inactivePot from '../../assets/growing-svg-effect/1.svg';
import info from '../../assets/info.svg'
import smPotFlower from '../../assets/pot-sm-flower.svg';
import moneyLeaf from '../../assets/money vector.svg';
import {SecondaryButton} from "../../components/secondaryButton/secondary-button";
import {PrimaryButton} from "../../components/primaryButton/primary-button";
import {useNavigate} from "react-router-dom";
import {
    calculateFutureValue,
    calculateNPER,
    calculatePMT,
    extractNumbers,
    formatNumber,
    lookupRate,
    lookupTable,
    monthToYears
} from "../../utils/financeUtils";

export const WithdrawalBreakdown = () => {
    const {withdrawalData} = useGlobalState();
    const navigate = useNavigate();

    const [withdrawAmt, setWithdrawAmt] = useState<Number>(0);
    const [adjustedWithdrawalPercentage, setAdjustedWithdrawalPercentage] = useState<any>(100);
    const [amtAfterTax, setAmtAfterTax] = useState<Number>(0);
    const [futureValue, setFutureValue] = useState<Number>(0);
    const [additionalContribution, setAdditionalContribution] = useState<Number>(0);
    const [additionalMonth, setAdditionalMonth] = useState<Number>(0);
    const [vestedAmt, setVestedAmt] = useState<Number>(0)

    const handleReset = () => {
        navigate('/');
    }

    const handleAdvisor = () => {
        window.location.href = "https://advice.alexforbes.com/index.html";
    }

    const plantContainerRef = useRef<HTMLDivElement | null>(null);
    const plantRef = useRef<HTMLDivElement | null>(null);
    const savingsPlantContainerRef = useRef<HTMLDivElement | null>(null);
    const savingsPlantRef = useRef<HTMLDivElement | null>(null);
    const [activeTab, setActiveTab] = useState<Number>(2);
    const currentSavings = Number(extractNumbers(withdrawalData?.currentSavings));
    const monthlySalary = Number(extractNumbers(withdrawalData?.monthlySalary));
    const age = Number(withdrawalData?.age);
    const monthlyContribution = Number(withdrawalData?.monthlyContribution);
    const retirementAge: number = Number(withdrawalData?.retirementAge);

    const assumptionRate: number = 0.1085;
    const monthlyAssumptionRate: number = 0.008620927;

    const handleTab = (tabNumber: number) => {
        setActiveTab(tabNumber);
    }

    useEffect(() => {
        const percent = convertToPercentages();
        setAdjustedWithdrawalPercentage(percent);
    }, [withdrawAmt]);

    const handleAdjustedWithdrawal = (e: ChangeEvent<HTMLInputElement>) => {
        const adjustedWithdraw = convertAdjustedWithdrawalMoney(e.target.valueAsNumber);
        setAdjustedWithdrawalPercentage(e.target.valueAsNumber);

        const futureValue = calculateFutureValue(assumptionRate, retirementAge - age, 0, -adjustedWithdraw, 0);
        setFutureValue(futureValue);

        const PMT = calculatePMT(monthlyAssumptionRate, (retirementAge - age) * 12, 0, -futureValue);
        setAdditionalContribution(PMT);

        const NPER = -calculateNPER(monthlyAssumptionRate, (monthlyContribution * 12 * Math.pow(1.0785, 33) / 12), Number(futureValue));
        setAdditionalMonth(NPER);

        const afterTaxes = Number(adjustedWithdraw) - lookupRate(((monthlySalary * 12) + adjustedWithdraw) - (12 * monthlyContribution), lookupTable, true) * Number(adjustedWithdraw);
        setAmtAfterTax(afterTaxes);
    }

    const convertAdjustedWithdrawalMoney = (adjustedWithdrawPercentage: number = 100): number => {
        return Number(withdrawAmt) * (adjustedWithdrawPercentage / 100)
    }

    const convertToPercentages = (adjustedWithdrawPercentage: number = 100): number => {
        return convertAdjustedWithdrawalMoney(adjustedWithdrawPercentage) / Number(withdrawAmt) * 100;
    }

    useEffect(() => {
        // Calculate withdrawal amount
        const withdrawAmt = Math.min(0.1 * currentSavings, 30000);

        // Calculate vested amount
        const vestedAmt = currentSavings - withdrawAmt;

        // Set state values (assuming set functions are defined)
        setWithdrawAmt(withdrawAmt);
        setVestedAmt(vestedAmt);
        setAdjustedWithdrawalPercentage(100);

        // Calculate future value
        const futureValue = calculateFutureValue(assumptionRate, retirementAge - age, 0, -withdrawAmt, 0);
        setFutureValue(futureValue);

        // Calculate additional contribution
        const additionalContribution = calculatePMT(monthlyAssumptionRate, (retirementAge - age) * 12, 0, -futureValue);
        setAdditionalContribution(additionalContribution);

        // Calculate additional months required
        const additionalMonth = -calculateNPER(monthlyAssumptionRate, (monthlyContribution * 12 * Math.pow(1.0785, 33)) / 12, futureValue);
        setAdditionalMonth(additionalMonth);

        // Calculate amount after tax
        const rate = lookupRate(((monthlySalary * 12) + withdrawAmt) - (12 * monthlyContribution), lookupTable, true);
        const amtAfterTax = withdrawAmt - rate * withdrawAmt;
        setAmtAfterTax(amtAfterTax);
    }, [withdrawAmt]);


    useEffect(() => {
        const plantContainer = plantContainerRef.current;
        const plant = plantRef.current;

        const savingsPlantContainer = savingsPlantContainerRef.current;
        const savingsPlant = savingsPlantRef.current;

        if (!plantContainer || !plant || !savingsPlantContainer || !savingsPlant) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        plant.classList.add('animate');
                    }
                });
            },
            {threshold: 0.6}
        );

        const savingsObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        savingsPlant.classList.add('animate');
                    }
                });
            },
            {threshold: 0.6}
        );

        observer.observe(plantContainer);
        savingsObserver.observe(savingsPlantContainer);

        return () => {
            observer.unobserve(plantContainer);
            savingsObserver.unobserve(savingsPlantContainer);
        };
    }, []);

    const scrollToElement = (elementId: string = "savings") => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    const {years, months} = monthToYears(Number(additionalMonth));

    return (
        <>
            <main className='container'>
                <div className="h-container container-limiter">
                    <h1 className="heading">Withdrawal breakdown:</h1>

                    <div className={"custom-divider"}>
                        <hr className={"divider"}/>
                        <img src={moneyLeaf} alt={"money leaf"}/>
                        <hr className={"divider"}/>
                    </div>

                    <h6 className={"paragraph"}>From <span>3 September 2024</span> you can withdraw up to:</h6>
                    <h1 className={"heading"}>R{Number(withdrawAmt) >= 2000 ? String(formatNumber(Number(withdrawAmt))) : 0}</h1>
                    <h6 className={"paragraph pointer"}>from your <a onClick={() => scrollToElement()}>new savings
                        pot.</a></h6>

                    <div className={"content"}>
                        <SecondaryButton text={'Reset calculator'} onClick={() => handleReset()}/>
                    </div>

                    <hr className={"divider"}/>

                    <div>
                        {Number(withdrawAmt) >= 2000 ? (
                                <>
                                    <h6 className={"paragraph"}>Your calculated withdrawal value is the lesser of
                                        either </h6>
                                    <h6 className={"paragraph"}><b>10% of your retirement savings</b> on 31 August 2024, <b>or
                                        R30 000.</b>
                                    </h6></>
                            )
                            :
                            <h6 className={"paragraph"}>
                                If you have less than R2 000 you can’t withdraw from your savings pot.
                            </h6>
                        }

                    </div>

                    {Number(withdrawAmt) >= 2000 ? (<>
                            <div className={"c-withdrawal"}>
                                <h6 className={"paragraph x-bold"}>Adjust your withdrawal:</h6>

                                <div className="c-range-holder">
                                    <input
                                        type="range"
                                        id="monthly-contribution"
                                        className="range-input"
                                        min={0}
                                        max={100}
                                        step={0.5}
                                        value={isNaN(convertToPercentages(adjustedWithdrawalPercentage)) ? 0 : convertToPercentages(adjustedWithdrawalPercentage)}
                                        onChange={handleAdjustedWithdrawal}
                                        required
                                    />
                                </div>


                                <div className={"w-amount"}>
                                    <div className={"w-a-data"}>
                                        <label>Withdrawal amount:</label>
                                        <h1 className={"heading"}>R{String(formatNumber(convertAdjustedWithdrawalMoney(adjustedWithdrawalPercentage)))}</h1>
                                    </div>

                                    <div className={"w-a-data"}>
                                        <label>Amount after withdrawal taxes:
                                            <span className="tooltip-container">
                                                    <img src={info} alt={"info"}/>
                                                    <span className="tooltip-text">
                                                        Tax deducted from withdrawn funds.
                                                    </span>
                                                </span>
                                        </label>
                                        <h1 className={"small-amount"}>R{convertAdjustedWithdrawalMoney(adjustedWithdrawalPercentage) > 0 ? String(formatNumber(Number(amtAfterTax))) : 0}</h1>
                                        <span
                                            className={"sm-text"}>Taxes = R{convertAdjustedWithdrawalMoney(adjustedWithdrawalPercentage) > 0 ? String(formatNumber(convertAdjustedWithdrawalMoney(adjustedWithdrawalPercentage) - Number(amtAfterTax))) : 0} </span>
                                    </div>
                                </div>

                                {adjustedWithdrawalPercentage !== 0 ?
                                    (
                                        <>
                                            <hr className={"divider  a-m"}/>

                                            <div className={"w-amount"}>
                                                <div className={"wag"}>
                                                    <h6 className={"paragraph"}>If you don't withdraw this amount it could
                                                        grow
                                                        to more than:</h6>
                                                    <h1 className={"small-amount"}>R{String(formatNumber(Math.round(Number(futureValue))))}</h1>
                                                    <h6 className={"paragraph"}>between now and your retirement. </h6>

                                                    <h5 className={"paragraph b-text"}>To catch up</h5>
                                                </div>
                                            </div>

                                            <div className={"w-amount"}>
                                                <div className={"wag"}>
                                                    <h6 className={"paragraph"}>You’ll need to contribute an extra:</h6>
                                                    <h1 className={"small-amount"}>R{String(Math.round(Number(additionalContribution)))} per
                                                        month</h1>
                                                    <h6 className={"paragraph"}>from now until retirement. </h6>
                                                </div>

                                                {years !== Infinity || !isNaN(months) ? <>
                                                    <div className={"wag"}>
                                                        <h6 className={"paragraph"}>Or work an extra: </h6>
                                                        <h1 className="small-amount">
                                                            {years > 0 ?
                                                                `${years} year${years > 1 ? 's' : ''} ${months > 0 ? `and ${months} month${months > 1 ? 's' : ''}` : ''}`
                                                                : `${months} month${months > 1 ? 's' : ''}`
                                                            }
                                                        </h1>

                                                        <h6 className={"paragraph"}>after retirement age.</h6>
                                                    </div>
                                                </> : ''}
                                            </div>
                                        </>
                                    ) : ''
                                }
                            </div>
                        </>)
                        : ''
                    }


                    <hr className={"divider"}/>

                    <h1 className={"heading"}>Your two-pot <br/>retirement summary: </h1>


                    {Number(withdrawAmt) >= 2000 ? (<>
                        <hr className={"divider"}/>

                        <div className={"w-tabs-container"}>
                            <div className={"w-tabs"}>
                                <div onClick={() => handleTab(1)}
                                     className={`w-tab ${activeTab === 1 ? 'active' : ''}`}>If
                                    you withdraw
                                </div>
                                <div onClick={() => handleTab(2)}
                                     className={`w-tab ${activeTab === 2 ? 'active' : ''}`}>If
                                    you don't
                                </div>
                            </div>
                        </div>
                    </>) : ''}

                    <hr className={"divider"}/>

                    <div className={"pots"} id={"savings"}>
                        <div className={"p-info"}>
                            <span
                                className={"p-money disabled"}>R{String(formatNumber(Math.round(Number(vestedAmt))))}</span>
                            <div className={"i-pot growing"}>
                                <div className={`plant-container`} ref={plantContainerRef}>
                                    <div ref={plantRef} className="plant"></div>
                                </div>
                            </div>
                            <h3 className={"heading"}>Your old pot (vested pot) </h3>
                            <span className={"smallest-text"}>Savings accumulated up to the date of <br/> implementation.</span>
                            <span className={"smallest-text t"}><b>Accessible at retirement and resignation.</b></span>
                        </div>

                        <div className={"p-info"}>
                            <span className={"p-money"}> 
                                {activeTab === 1 ?
                                    `R${Number(withdrawAmt) - convertAdjustedWithdrawalMoney(adjustedWithdrawalPercentage) === 0 ? 0 : formatNumber(Number(withdrawAmt) - convertAdjustedWithdrawalMoney(adjustedWithdrawalPercentage))}`
                                    : `R${formatNumber(Number(withdrawAmt))}`
                                }
                                </span>
                            <div className={"i-pot"}>
                                <div className={`savings-pot`} ref={savingsPlantContainerRef}>
                                    <div ref={savingsPlantRef} className="savings-plant"></div>
                                </div>
                            </div>
                            <h3 className={"heading"}>Your new savings pot</h3>
                            <span className={"smallest-text"}>This includes one-third of your contributions from 3 September 2024 onwards.
                            </span>
                            <span className={"smallest-text t"}><b>Accessible once a year.</b></span>
                        </div>

                        <div className={"p-info"}>
                            <span className={"p-money disabled"}>Active after first contribution</span>
                            <div className={"i-pot"}>
                                <img src={inactivePot} className={"disabled"} alt={"inactive pot"} width={"135px"}/>
                            </div>
                            <h3 className={"heading"}>Your new retirement pot</h3>
                            <span className={"smallest-text"}>This includes two-thirds of your contributions from 3 September 2024 onwards.</span>
                            <span className={"smallest-text t"}><b>Accessible at retirement only.</b></span>
                        </div>
                    </div>

                    <hr className={"divider"}/>

                    <div className={"c-withdrawal"}>
                        <span className={"paragraph"}>
                            As a financial services group, your financial future matters to us, and we want you to be in control. 
                            <br/>
                            <br/>
                            <b> Consider this:</b> only about 6% of South Africans can retire comfortably at 65, so careful financial planning and saving are crucial.
                            <br/>
                            <br/>
                            Some reasons to consider withdrawing cash, under the guidance of a financial adviser:
                            <br/>
                            <br/>
                            <ul>
                                <li>Debt repayment</li>
                                <li>Building emergency savings</li>
                                <li>Well-researched investment opportunities</li>
                            </ul>
                            
                            <br/>
                            We recommend keeping your retirement savings invested for retirement where possible. This will give you the best chance of having enough money to live on when you stop working.
                        </span>

                        <div className={"banner"}>
                            <div className={"banner-content"}>
                                <h1 className={"b-text"}>Step toward your planned financial future. </h1>

                                <p className={"paragraph"}>Reach out to our team of professional financial advisers to get your tomorrow on track, today.</p>

                                <div>
                                    <PrimaryButton text={'Find an adviser'} onClick={() => handleAdvisor()}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className={"divider"}/>

                    <div className="paragraph left">

                        <p><strong>Disclaimer</strong></p>
                        <br/>
                        <p><strong>Note:</strong></p>
                        <ol>
                            <li>The total marginal tax to be deducted is determined by SARS and may differ from
                                the amount above. SARS may also deduct any outstanding tax from the total amount
                                withdrawn.
                            </li>
                            <li>The savings pot will be accessible at any time, but withdrawals must be a minimum of
                                R2 000. You may only withdraw from your savings pot once in a tax year
                            </li>
                            <li>Transaction fees may be charged by administrators for processing a savings withdrawal
                                claim, which will also be deducted from the claim amount
                            </li>
                            <li>SARS will notify Alexforbes what amount of tax to deduct and Alexforbes will deduct that
                                amount from the savings claim amount.
                            </li>
                            <li>Once Alexforbes has applied for a tax directive from SARS, the savings claim cannot be
                                cancelled.
                            </li>
                            <li>At the end of the tax year when you do your tax filing, you may have to pay in extra
                                tax to SARS or SARS may give you a refund if the correct tax was not levied by SARS when
                                your savings claim was processed. For example if your marginal tax rate is 30%, but SARS
                                requires a 45% deduction off the claim, then you will have to claim back money from SARS
                                at the end of the tax year.
                            </li>
                            <li>The initial seeding capital moved to the savings pot is a once-off event from 3
                                September
                                2024. This is 10% of your fund credit (known as the vested pot) on 2 September 2024, to a
                                maximum of R30 000.
                            </li>
                            <li>The investment return assumed for the above is CPI + 5%.</li>
                            <li>Salary is assumed to increase yearly by 2% more than the inflation rate of 5.85% a year.</li>
                            <li>Withdrawals from the savings pot will incur a fee of R100 for amounts between R2 000 and
                                R5 000, and a fee of 2% (up to R600) for amounts over R5 000. Both fees will be adjusted
                                annually for inflation.
                            </li>
                        </ol>
                        <br/>
                        <p className={"sentence"}>The initial two-pot seed calculator, created by Alexforbes, is a tool
                            designed to estimate the initial seed amount in your savings pot under the two-pot system
                            and project the tax implications of withdrawals from this pot. It provides valuable insights
                            into how withdrawing from your savings pot can impact your retirement outcome and calculates
                            the additional savings required to replenish withdrawn amounts.
                        </p>

                        <br/>
                        <p><strong>Key Features:</strong></p>

                        <ol>
                            <li><strong>Initial seed calculation:</strong> determine the initial amount allocated to
                                your savings pot based on your value in the fund as at 2 September 2024.
                            </li>
                            <li><strong>Withdrawal impact:</strong> understand how savings withdrawals can influence
                                your retirement income and plan accordingly.
                            </li>
                            <li><strong>Replenishment planning:</strong> determine the extra amount that needs to be
                                saved to replace the withdrawn amount for a secure retirement.
                            </li>
                        </ol>

                        <br/>
                        <p><strong>Disclaimer:</strong></p>

                        <ol>
                            <li><strong>Educational purposes:</strong> this calculator is intended for educational and
                                informational purposes only. It should not be seen as financial advice. Users are
                                encouraged to consult with a qualified financial adviser before making any financial
                                decisions based on the calculations provided.
                            </li>
                            <li><strong>Assumptions and estimates:</strong> the calculations and projections provided by
                                this calculator are based on certain assumptions and estimates. Actual results may vary,
                                and the calculator's accuracy is not guaranteed.
                            </li>
                            <li><strong>No liability:</strong> Alexforbes will not be held liable for any errors or
                                omissions in the calculations, or any actions taken based on the information provided by
                                the calculator. Users assume full responsibility for using the calculator and any
                                decisions made based on its output.
                            </li>
                            <li><strong>Periodic review:</strong> users are advised to periodically review their
                                retirement plans and financial strategies, because changes in personal circumstances,
                                market conditions and tax laws may affect the outcomes projected by this calculator.
                            </li>
                            <li><strong>Personal data:</strong> any personal data entered into the calculator is used
                                solely to generate the calculations and is not stored, shared or used for any other
                                purpose.
                            </li>
                        </ol>
                        <br/>
                        <p className={"sentence"}>By using this calculator, you acknowledge and agree to the terms of
                            this disclaimer. Empower yourself with the knowledge to make informed decisions about your
                            retirement savings. Plan for the future with confidence and take control of your financial
                            well-being.</p>

                        <p className={"sentence"}>For more information on the two-pot-system, please visit: <a
                            href="https://mymoneymatters.alexforbes.com/twopot.html"
                            className={"link-color"}> Alexforbes two-pot-system</a>
                        </p>

                        <br/>

                        <p className={"sentence"}><strong>For guidance on IT 88 and marginal tax please visit the SARS
                            website on</strong> <a
                            href="https://www.sars.gov.za"
                            className={"link-color"}>www.sars.gov.za</a>
                        </p>
                    </div>

                </div>
            </main>
        </>
    )
}
