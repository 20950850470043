import React from 'react';
import './App.scss';
import {Navbar} from './components/navbar/navbar';
import {RetirementWithdrawalCalculator} from './pages/RetirementWithdrawalCalculator/RetirementWithdrawalCalculator';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {WithdrawalBreakdown} from "./pages/WithdrawalBreakdown/withdrawalBreakdown";
import {GlobalStateProvider} from "./Contexts/GlobalState";
import {Footer} from "./components/footer/footer";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

const App: React.FC = () => {
    return (
        <>
            <GlobalStateProvider>
                <BrowserRouter>
                    <Navbar/>
                    <ScrollToTop/>
                    <Routes>
                        <Route path='' element={<RetirementWithdrawalCalculator/>}/>
                        <Route path='breakdown' element={<WithdrawalBreakdown/>}/>
                    </Routes>
                </BrowserRouter>
            </GlobalStateProvider>
            <Footer/>
        </>
    );
}

export default App;
