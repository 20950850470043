import React from "react";


export const Footer: React.FC = () => {
    const year = new Date().getFullYear();
    return (
        <footer className={"footer"}>
            Copyright {year} Alexander Forbes Group Holdings Limited. Legal terms | Privacy policy | PAIA manual
        </footer>
    )
}