import React from "react";

interface Props {
    text: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    onClick?: () => void;
}

export const PrimaryButton: React.FC<Props> = ({text, type, onClick}) => {
    return (
        <button type={type} className="p-btn" onClick={onClick}>
            {text}
        </button>
    );
}