import React, {ChangeEvent, FormEvent, useState} from 'react'
import './RetirementWithdrawalCalculator.scss';
import info from '../../assets/info.svg';
import {PrimaryButton} from "../../components/primaryButton/primary-button";
import {IWithdrawalForm, useGlobalState} from "../../Contexts/GlobalState";
import {useNavigate} from "react-router-dom";
import {SecondaryButton} from "../../components/secondaryButton/secondary-button";
import {extractNumbers, handleContributionPercentage} from "../../utils/financeUtils";

export interface FormErrors {
    currentSavings?: string,
    monthlySalary?: string,
    age?: string,
    retirementAge?: string;
    monthlyContribution?: string
}

export const RetirementWithdrawalCalculator: React.FC = () => {
    const {setWithdrawalData} = useGlobalState();
    const navigate = useNavigate();

    const [formData, setFormData] = useState<IWithdrawalForm>({
        currentSavings: '',
        monthlySalary: '',
        age: 18,
        retirementAge: 55,
        monthlyContribution: 0
    });

    const [errors, setErrors] = useState<FormErrors>({});

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        const isNumber = !isNaN(Number(value.replace(/[^0-9]/g, '')));
        if (!isNumber) {
            // Optionally, set an error message or skip updating the state
            return;
        }
        let formattedValue = value;

        // Format the value if the input is meant for currency
        if (name === "currentSavings" || name === "monthlySalary") {
            formattedValue = formatCurrencyInput(value);
        }

        setFormData({
            ...formData,
            [name]: formattedValue
        });

        const validationErrors = validate();

        setErrors(validationErrors);
    }

    const validate = (): FormErrors => {
        const newErrors: FormErrors = {};

        if (!formData.currentSavings && !Number(formData.currentSavings)) newErrors.currentSavings = 'Current savings is required';
        if (formData.retirementAge) {
            if (formData.age >= formData.retirementAge) {
                newErrors.age = "You must be younger than your retirement age."
            }
        }

        return newErrors;
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationErrors = validate();

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setWithdrawalData({
                ...formData,
                monthlyContribution: handleConvertToMoney(formData.monthlyContribution)
            });

            navigate('/breakdown');
        }
    }

    const formatCurrencyInput = (input: string): string => {
        // Remove any non-digit characters
        let sanitizedInput = input.replace(/[^0-9]/g, '');

        // Convert the input to a number
        let number = parseInt(sanitizedInput, 10);

        // Check if the number is valid
        if (isNaN(number)) {
            return ''; // Default to R if input is invalid
        }

        // Format the number with spaces as thousand separators
        let formattedNumber = number.toLocaleString('en-ZA').replace(/,/g, ' ');

        // Append "R " at the beginning
        return `R${formattedNumber}`;
    };


    const handleResetCalculator = () => {
        setFormData({
            age: 18,
            retirementAge: 55,
            monthlyContribution: 0,
            monthlySalary: '',
            currentSavings: ''
        });
    }

    const handleConvertToMoney = (contributionPercentage: number): number => {
        return Math.round(extractNumbers(formData.monthlySalary) * (contributionPercentage / 100))
    }

    //Max contribution 27.5%
    const handleMaxContribution = (monthlySalary: string): number => {
        const numberSalary = extractNumbers(monthlySalary);
        return isNaN((numberSalary * 0.275) / numberSalary * 100) ? 0 : (numberSalary * 0.275) / numberSalary * 100;
    }

    return (
        <div className='container'>
            <form className="h-container container-limiter" onSubmit={handleSubmit}>
                <h6 className='paragraph'>The two-pot system is live: <span>3 September 2024</span></h6>
                <h1 className='heading'>Calculate your first two-pot withdrawal!</h1>

                <h6 className='paragraph'>See how much you'll get after tax with your first savings pot withdrawal.</h6>

                <hr className="divider"/>

                <div className="calculator-container">
                    <div className="c-item-groups">
                        <div className="c-item-input">
                            <label htmlFor="current-savings">
                                Total value of your retirement savings
                                <span className="tooltip-container">
                                    <img src={info} alt={"info"}/>
                                    <span className="tooltip-text">
                                        This is the amount you have currently saved for retirement.
                                    </span>
                                </span>
                            </label>
                            <input
                                id="current-savings"
                                className="text-input"
                                type="text"
                                placeholder="R"
                                name={"currentSavings"}
                                value={formData.currentSavings}
                                onChange={(event) => handleChange(event)}
                                autoComplete={"off"}
                            />
                            {errors.currentSavings && <span className={"error"}>{errors.currentSavings}</span>}
                        </div>

                        <div className="c-item-input">
                            <label htmlFor="monthly-savings">
                                Your monthly salary
                                <span className="tooltip-container">
                                    <img src={info} alt={"info"}/>
                                    <span className="tooltip-text">
                                       Your gross monthly income before taxes and deductions.
                                    </span>
                                </span>
                            </label>
                            <input
                                id="monthly-savings"
                                className="text-input"
                                placeholder="R"
                                type="text"
                                name={"monthlySalary"}
                                value={formData.monthlySalary}
                                onChange={(event) => handleChange(event)}
                                autoComplete={"off"}
                            />
                            {errors.monthlySalary && <span className={"error"}>{errors.monthlySalary}</span>}
                        </div>
                    </div>

                    <hr className={"divider"}/>

                    <div className="c-item-groups">
                        <div className="c-item-input">
                            <label htmlFor="age">Your age</label>
                            <span className="c-data">{formData.age} years old</span>
                            <div className="c-range-holder">
                                <input
                                    type="range"
                                    id="age"
                                    className="range-input"
                                    name={"age"}
                                    min={18}
                                    max={64}
                                    minLength={18}
                                    value={formData.age}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </div>
                            {errors.age && <span className={"error"}>{errors.age}</span>}
                        </div>

                        <div className="c-item-input">
                            <label htmlFor="retirementAge">Retirement age
                                <span className="tooltip-container">
                                    <img src={info} alt={"info"}/>
                                    <span className="tooltip-text">
                                      You must be younger than your retirement age.
                                    </span>
                                </span>
                            </label>
                            <span className="c-data">{formData.retirementAge} years old</span>
                            <div className="c-range-holder">
                                <input
                                    type="range"
                                    id="retirementAge"
                                    className="range-input"
                                    name={"retirementAge"}
                                    min={55}
                                    max={65}
                                    minLength={55}
                                    value={formData.retirementAge}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </div>
                            {errors.retirementAge && <span className={"error"}>{errors.retirementAge}</span>}
                        </div>

                        <div className="c-item-input">
                            <label htmlFor="monthly-contribution">
                                Your monthly contribution
                                <span className="tooltip-container">
                                    <img src={info} alt={"info"}/>
                                    <span className="tooltip-text">
                                        Amount you contribute monthly towards a specific fund or savings.
                                    </span>
                                </span>
                            </label>
                            <span className="c-data">R{handleConvertToMoney(formData.monthlyContribution)}
                                <span
                                    className={"pct"}> ({handleContributionPercentage(handleConvertToMoney(formData.monthlyContribution), formData?.monthlySalary)}%)</span></span>
                            <div className="c-range-holder">
                                <input
                                    type="range"
                                    id="monthly-contribution"
                                    className="range-input"
                                    name={"monthlyContribution"}
                                    min={0}
                                    max={handleMaxContribution(formData.monthlySalary)}
                                    step={0.5}
                                    value={formData.monthlyContribution}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </div>
                            {errors.monthlyContribution &&
                                <span className={"error"}>{errors.monthlyContribution}</span>}
                        </div>
                    </div>
                </div>

                <hr className="divider"/>

                <div className={"actions"}>
                    <SecondaryButton text={"Reset calculator"} onClick={() => handleResetCalculator()}/>
                    <PrimaryButton type={"submit"} text={"Calculate"}/>
                </div>
            </form>
        </div>
    )
}
