// src/financeUtils.ts

/**
 * Calculate the future value of an investment.
 * @param {number} rate - The interest rate per period.
 * @param {number} periods - The total number of payment periods in the investment.
 * @param {number} payment - The payment made each period; it cannot change over the life of the investment.
 * @param {number} presentValue - The present value, or the lump-sum amount that a series of future payments is worth right now.
 * @param {number} type - The timing of the payment (0 = end of the period, 1 = beginning of the period).
 * @returns {number} The future value of the investment.
 */
export const calculateFutureValue = (
    rate: number,
    periods: number,
    payment: number,
    presentValue: number,
    type: number
): number => {
    if (rate === 0) {
        return -1 * (presentValue + payment * periods);
    } else {
        //Present Value Interest Factor
        const pvif = Math.pow(1 + rate, periods);
        
        return -1 * (presentValue * pvif + payment * (1 + rate * type) * ((pvif - 1) / rate));
    }
};


/**
 * Calculate the payment for a loan based on constant payments and a constant interest rate.
 * @param {number} rate - The interest rate per period.
 * @param {number} nper - The total number of payment periods.
 * @param {number} pv - The present value (the total amount that a series of future payments is worth now).
 * @param {number} [fv=0] - The future value (optional, default is 0).
 * @param {number} [type=0] - The timing of the payment: 0 for end of period, 1 for beginning (optional, default is 0).
 * @returns {number} The payment for the loan.
 */
export const calculatePMT = (
    rate: number,
    nper: number,
    pv: number,
    fv: number = 0,
    type: number = 0
): number => {
    if (rate === 0) {
        return -(pv + fv) / nper;
    }
    const pvif = Math.pow(1 + rate, nper);
    const pmt = rate / (pvif - 1) * -(pv * pvif + fv);
    if (type === 1) {
        return pmt / (1 + rate);
    }
    return pmt;
};


// src/financeUtils.ts

/**
 * Calculate the number of periods for an investment.
 * @param {number} rate - The interest rate per period.
 * @param {number} pmt - The payment made each period.
 * @param {number} pv - The present value (the total amount that a series of future payments is worth now).
 * @param {number} [fv=0] - The future value (optional, default is 0).
 * @param {number} [type=0] - The timing of the payment: 0 for end of period, 1 for beginning (optional, default is 0).
 * @returns {number} The number of periods for the investment.
 */
export const calculateNPER = (
    rate: number,
    pmt: number,
    pv: number,
    fv: number = 0,
    type: number = 0
): number => {
    // If rate is 0, simply divide the total amount by the payment amount
    if (rate === 0) {
        return -(pv + fv) / pmt;
    }

    // Calculate the number of periods (nper)
    return Math.log((pmt * (1 + rate * type) - fv * rate) / (pv * rate + pmt * (1 + rate * type))) / Math.log(1 + rate);
};


interface RateEntry {
    startValue: number;
    rate: number;
}

export const lookupTable: RateEntry[] = [
    {startValue: 237100, rate: 0.18},
    {startValue: 370500, rate: 0.26},
    {startValue: 512800, rate: 0.31},
    {startValue: 673000, rate: 0.36},
    {startValue: 857900, rate: 0.39},
    {startValue: 1817000, rate: 0.41},
    {startValue: Infinity, rate: 0.45},
];

/**
 * Looks up the rate for a given value using a list of RateEntry objects.
 * @param {number} lookupValue - The value to search for in the startValue of RateEntry.
 * @param {RateEntry[]} table - The table where the lookup is performed.
 * @param {boolean} [approximateMatch=true] - Whether to find an approximate match (default is true).
 * @returns {number} The rate found in the table.
 */
export const lookupRate = (
    lookupValue: number,
    table: RateEntry[],
    approximateMatch: boolean = true
): number => {
    let foundRate: number | null = null;
 
    // Loop through each RateEntry in the table
    for (let i = 0; i < table.length; i++) {
        if (approximateMatch) {
            // Update the found rate only if the startValue is less than or equal to the lookupValue
            if (lookupValue < table[i].startValue) {
                foundRate = table[i].rate;
                break;
            }
        } else {
            // For exact match
            if (table[i].startValue === lookupValue) {
                foundRate = table[i].rate;
                break;
            }
        }
    }

    if (foundRate === null) {
        return 0
    }

    return foundRate;
};

/**
 * 
 * @param value - any number value
 * @return formated number with a thousand separator of a space
 */
export function formatNumber(value: number) {
    const roundedValue = Math.round(Number(value));
    return new Intl.NumberFormat('en-US', {useGrouping: true}).format(roundedValue).replace(/,/g, ' ');
}

/**
 * 
 * @param contribution - Amount you contribute monthly
 * @param monthlySalary - Gross salary
 * @return A string containing a numeric value of the percentage you contribute a month
 */
export const handleContributionPercentage = (contribution: number, monthlySalary: string): string => {
    const monthlySalaryNum = Number(monthlySalary.replace(/[^0-9]/g, ''));
    if (monthlySalaryNum)
        return ((contribution / Number(monthlySalaryNum)) * 100).toFixed(2)
    else
        return '0'
}

/**
 * Extracts numeric characters from a string, removing all other characters.
 *
 * @param input - The input string to extract numbers from.
 * @returns A string containing only the numeric characters.
 */
export const extractNumbers = (input: string | undefined): number => {
    if(input)
        return Number(input.replace(/[^0-9]/g, ''));
    return 0
};

/**
 * 
 * @param months 
 * @return years and months in an object
 */
export const monthToYears = (months: number): { years: number; months: number } => {
    const years = Math.floor(months / 12) ?? 0;
    const remainingMonths = Math.round(months % 12);
    return {
        years: years,
        months: remainingMonths
    };
};

