import React from 'react'
import logo from '../../assets/af-logo.svg';
import './navbar.scss';
import {useNavigate} from "react-router-dom";

export const Navbar: React.FC = () => {
    const navigate = useNavigate();
    
    return (
        <nav className='navbar container'>
            <div className="container-limiter">
                <img src={logo} alt='af-logo' className={"logo"} onClick={() => navigate('/')}/>
            </div>
        </nav>
    )
}
